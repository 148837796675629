import {createWebHistory, createRouter} from 'vue-router';
import {nextTick} from 'vue';
import store from './store';
import Login from './components/Login.vue';
import Logout from './components/Logout.vue';
import ResetPassword from './components/Partner/ResetPassword';
import ChangePassword from './components/Partner/ChangePassword';
import RemindPassword from './components/Partner/RemindPassword';
import NewSubmissionShort from "./components/Submission/NewSubmissionShort";
import ViewSubmission from "./components/Submission/ViewSubmission";
import EditSubmission from "./components/Submission/EditSubmission";
import LoginRedirect from "./components/LoginRedirect";
import PageNotFound from "./components/PageNotFound";
// lazy-loaded
const EditPartner = () => import('./components/Partner/EditPartner.vue')
const ViewPartner = () => import('./components/Partner/ViewPartner.vue')
const Dashboard = () => import('./components/Dashboard.vue')
const Submissions = () => import('./components/Submission/SubmissionList.vue')

const routes = [
    {
        path: '/',
        name: 'home',
        component: Dashboard,
        meta: {
            title: 'Referral Provider Home',
            requiresAuth: true,
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Referral Provider Login',
            hideHeader: true,
            unAuthenticatedOnly: true
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/partner/:partner_id/edit',
        name: 'EditPartner',
        component: EditPartner,
        meta: {
            title: 'Change profile',
            requiresAuth: true,
        },
    },
    {
        path: '/partner/:partner_id',
        name: 'ViewPartner',
        component: ViewPartner,
        meta: {
            title: 'User Profile',
            requiresAuth: true,
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/password/reset',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            title: 'Reset password',
            requiresAuth: false,
            hideHeader: true
        },
    },
    {
        path: '/password/change',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            title: 'Change password',
            requiresAuth: true,
        },
    },
    {
        path: '/password/remind',
        name: 'ForgotPassword',
        component: RemindPassword,
        meta: {
            title: 'Forgot password',
            requiresAuth: false,
            unAuthenticatedOnly: true,
            hideHeader: true
        },
    },
    {
        path: '/submission/new',
        name: 'NewSubmission',
        component: NewSubmissionShort,
        meta: {
            title: 'New Referral Submission',
            requiresAuth: true,
        }
    },
    {
        path: '/submission/:submission_id/edit',
        name: 'EditSubmission',
        component: EditSubmission,
        meta: {
            title: 'Change Submission',
            requiresAuth: true,
        }
    },
    {
        path: '/submission/:submission_id',
        name: 'ViewSubmission',
        component: ViewSubmission,
        meta: {
            title: 'View Submission..',
            requiresAuth: true,
        }
    },
    {
        path: '/submissions',
        name: 'Submissions',
        component: Submissions,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/login-redirect',
        name: 'LoginRedirect',
        component: LoginRedirect,
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
            requiresAuth: false,
        }
    },
];

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const loggedIn = store.state.auth.status.loggedIn;

    let newTitle = 'Referral Provider Dashboard';
    if (to.matched.length > 0 && to.matched[0].meta && to.matched[0].meta.title) {
        newTitle = to.matched[0].meta.title;
    }

    nextTick(() => {
        document.title = newTitle;
    });

    if (to.meta.requiresAuth && !loggedIn) {
        next('/login')
    } else if (loggedIn && to.meta.unAuthenticatedOnly) {
        next('/dashboard')
    } else {
        next()
    }
});

export default router;
