<template>
  <div class="kapitus-note terms mt-4 mb-4 p-3">
    <h5 class="text-center">TERMS OF USE AGREEMENT</h5>
    <div>
      <section>
        <div>
          <h5>Last Revised: November 01, 2022</h5>
          <p class="fw-600">IMPORTANT NOTICE: AS DETAILED IN <a href="#arbitrationNotice">THE ARBITRATION; JURY TRIAL WAIVER; CLASS ACTION WAIVER PROVISIONS BELOW</a>, 
          THIS AGREEMENT CONTAINS A WAIVER OF CLASS ACTION RIGHTS AND A WAIVER OF THE RIGHT TO A JURY TRIAL, AND MAY REQUIRE YOU TO ARBITRATE CERTAIN DISPUTES.</p>
          <p>This website (including all sub-sites) is operated by Strategic Funding Source, Inc. (d/b/a Kapitus) or a subsidiary or affiliate thereof (collectively, 
            “Kapitus,” “we,” or “us”).</p>
          <p>By accessing a Kapitus website, mobile application, interface, or platform
            (collectively, “Site” or “Sites”) that links to this Terms of Use Agreement (the
            “Terms of Use” or “Agreement”), you agree to abide by this Agreement and by our
            <a target="_blank" rel="noopener" href="https://kapitus.com/privacy-policy/">Privacy Policy</a> (which is hereby incorporated by reference), regardless of the device
            used (e.g., personal computer, mobile device, or any other technology or software)
            for access.</p>
          <p>These Terms of Use govern your access to, and use of, the Sites and any of the
            tools, features, functionality, services, or products offered on or through the Sites,
            including your access to information and data; your use of any web portal,
            customer account, sales partner account, syndicate investor account, or online
            services; your submission of application and/or provision of information for
            qualification for financing or other purposes; your subscription to blogs,
            newsletters, and alike; and your participation in any public areas on the Sites
            (collectively, the ”Services”).</p>
          <p>In addition to these Terms of Use and the Privacy Policy, certain of our services
            offered through the Sites, such as your ability to submit requests for commercial
            financing and related services, will be subject to, and governed by, separate terms
            and conditions or contracts that will be provided to you, and that you must agree
            to, in connection with those requests or transactions. To the extent there is a
            conflict between these Terms of Use and a transaction-specific agreement, the
            transaction-specific agreement will govern.</p>
          <p class="fw-600">PLEASE READ THESE TERMS OF USE CAREFULLY TO UNDERSTAND EACH
            PROVISION. THIS CONSTITUTES A LEGAL AGREEMENT BETWEEN YOU AND
            KAPITUS FOR THE USE OF THE SERVICES AND THE TERMS OF USE SHALL
            APPLY TO ALL USERS OF THE SITES. IF YOU DO NOT AGREE TO THESE
            TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
            SITES, SERVICES, AND/OR CONTENT.</p>
          <p>We may update these Terms of Use from time to time by making available a
            revised, dated version on the Sites. If the revised version includes a substantial
            change, we will provide a more prominent notice (including, for certain services, an
            email or other type of notification of Terms of Use changes) prior to the change
            becoming effective. Your continued use of the Sites shall constitute your
            acceptance of such updated Terms of Use. We encourage you to periodically review
            this page for the latest information.</p>

          
          <h6>1. USE OF SITES</h6>
          <p>You may view and use the Sites and/or any of the information or Services only in
            accordance with these Terms of Use. You agree to use the Sites and/or Services
            only for lawful purposes and you acknowledge that your failure to do so may
            subject you to civil and criminal liability as determined by applicable law.</p>
          <p>The Sites are for individual business owners and legal entities organized and
            authorized to do business in the United States, and are not intended for use by
            others or for use outside of the United States.</p>
          <p>To access certain features of the Site, you may need to register for an account and
            to provide us certain documentation or information, including information about
            your identity, finances, and business performance.</p>
          <p>By creating or accessing a user account, submitting a request for commercial
            financing, and/or otherwise using the Sites, you represent and warrant that: (i) you
            are 18 years old or older and are fully able to enter into a binding agreement; (ii)
            all information and/or documentation you submit is true, accurate, current, and
            complete; (iii) you will update such information, as necessary, to keep it true,
            accurate, current, and complete; and (iv) the information belongs to you and does
            not infringe or violate any patents, copyrights, trademarks or other intellectual
            property, proprietary or privacy rights of any third party.</p>
          <p>Kapitus reserves the right, at its sole discretion, to restrict in whole or in part, your
            access to, and use of, the Sites, Services, and Site content at any time, with or
            without notice, and for any or no reason.</p>
          
          
          <h6>2. PROHIBITIONS ON USE</h6>
          <p>You agree that you will not violate any law, contract, intellectual property, or other
            third party right, and that you are solely responsible for your conduct, while
            accessing or using the Sites or using the Services. As noted above, the Sites and
            Services may only be used for lawful purposes and in accordance with this
            Agreement. Additionally, you specifically agree that you will not:</p>
          <ul>
            <li>Provide false or misleading information to Kapitus, impersonate any person
              or entity, or misrepresent your affiliation with a person or entity;</li>
            <li>Use the Site in any manner that violates any federal, state, international, or
              local law or regulation, or engage in, facilitate, encourage, or promote any
              activity that violates the Terms of Use;</li>
            <li>Use the Site in any manner that could interfere with, disrupt, negatively
              affect, or inhibit other users from fully enjoying the Site, or that could
              damage, disable, overburden, or impair the functioning of the Site in any
              manner;</li>
            <li>Reverse engineer any aspect of the Site, or do anything that might discover
              source code or bypass or circumvent measures employed to prevent or limit
              access to any service, area, or code of the Site;</li>
            <li>Use the Site in any matter that could threaten, bypass, or destroy any
              security mechanism used with or incorporated in the Site;</li>
            <li>Post or otherwise transmit any content, data, or material that contains
              viruses, spyware, spiders, robots, worms, Trojan horses, logic bombs, or any
              other type of malicious or deleterious programs;</li>
            <li>Use or attempt to use another user’s account without authorization from such
              user and Kapitus;</li>
            <li>Post or otherwise transmit inaccurate, misleading, deceptive, offensive, lewd,
              hateful, defamatory, libelous, abusive, illegal, discriminatory, or otherwise
              inappropriate or objectionable content;</li>
            <li>Post or otherwise transmit any unsolicited or unauthorized advertising,
              promotional materials, “junk mail”, “spam”, “chain letters”, “pyramid
              schemes” or any other material that contains business solicitations of any
              type, including advertising a product or service, offering a product or service
              for sale, or directing readers to a location for more information about a
              product or service;</li>
            <li>Post or otherwise transmit any content, data, or material that infringes on
              the intellectual property rights or other personal rights (including privacy) of
              any third parties.</li>
          </ul>
          
          
          <h6>3. ACCOUNT SECURITY</h6>
          <p>Each user is responsible for any misuse of his or her account, even if the
            inappropriate activity was committed by someone other than the account holder
            (i.e., his or her family member or friend, for example). Therefore, each user must
            take all reasonable steps to protect his or her account from unauthorized access.
            In addition, no user may access his or her account to breach the security of any
            other user&#39;s account or attempt to gain unauthorized access to another server or
            network. As such, each user must always ensure the security of his or her
            password, including without limitation, by not sharing it with others for any reason,
            creating passwords that are hard to guess, and updating passwords on a regular
            basis. Kapitus reserves the right to terminate your access to the Sites or cancel
            your username and password at any time, without notice, and for any reason
            including, without limitation, your violation of these Terms of Use.</p>
          
          
          <h6>4. OWNERSHIP, COPYRIGHTS, TRADEMARKS, LICENSES</h6>
          <p>Except for User Content (as defined below), any and all information, materials,
            images, software, photographs, articles, functions, text, and other content solely
            provided by or on behalf of Kapitus on any Site (collectively, “Site Content”) is the
            sole and exclusive property of Kapitus or our licensors, as applicable.</p>
          <p>The Sites, Site Content, and the selection and arrangement thereof, are protected
            by copyright, trademark, service mark, trade name, and other intellectual property
            and other proprietary rights, and all other applicable rights are reserved, and users
            shall abide by those laws. Kapitus reserves all rights not expressly granted in and
            to the Sites, Services, and the Site Content. Unless otherwise noted, the Kapitus
            trademark, the Kapitus name, Kapitus abbreviation and all other Kapitus service
            marks, trade names, logos or other designations of source displayed on the Sites
            are the property of Kapitus, and may not be copied, imitated, or used, in whole or
            in part, without Kapitus’s prior written permission. All third-party trademarks,
            service marks, trade names, logos, or other designations of source are the property
            of their respective owners, and may not be copied, imitated, or used, in whole or in
            part, without the permission of the lawful trademark holder.</p>
          <p>By furnishing Site Content, Kapitus does not grant any licenses to, or transfer any
            title rights for, any copyrights, patents, or any other intellectual property rights.
            Nothing on any Site shall be construed as granting any license or right not
            expressly set forth herein. Unless otherwise expressly stated herein, no part of the
            Site, including any Site Content, may be reproduced, modified, electronically
            transmitted, or otherwise copied or exploited for any purpose whatsoever without
            the express written permission of the intellectual property owner.</p>
          
          
          <h6>5. USER CONTENT</h6>
          <p>All information or other content conveyed or communicated by you to Kapitus as a
            contribution to any of the Sites (each, a “Submission”) shall comply with these
            Terms of Use and, upon receipt, will become property of Kapitus. Kapitus will not
            be required to treat any Submission as confidential and will not be liable for the use
            of any ideas (including without limitation, any product, service, or advertising
            ideas) and will not incur any liability as a result of any similarities that may appear
            in our future products, services, or operations. Kapitus shall have the right to use
            any content contained in a Submission for any purpose, without acknowledging the
            source of that Submission, and without compensation to you or any other person
            sending that Submission.</p>
          <p>Kapitus may permit the posting of text, files, images, photos, video, sounds,
            musical works, works of authorship, and other materials and content by you and
            other users (“User Content”) on certain areas of our Sites, including those that are
            interactive and accessible to the public. By submitting or posting User Content, you
            understand and acknowledge that any materials, ideas, or other communications
            you submit or post in any manner and for any reason will not be treated as
            confidential or proprietary and may be preserved, disclosed, or removed from the
            Sites by Kapitus at its sole discretion. Kapitus has the right to accept, post, refuse,
            remove, or delete any User Content for any or no reason (including User Content
            that Kapitus considers violative of these Terms of Use or otherwise illegal or
            objectionable). Notwithstanding the foregoing, you understand and acknowledge
            that Kapitus has no responsibility to monitor any materials submitted, posted,
            transmitted, or communicated to or within the Sites. That said, if there is
            something on the Sites that you’d like to bring to our attention because you think it
            violates the Terms of Use, please email <a target="_blank" rel="noopener" href="mailto:ipcompliance@kapitus.com">ipcompliance@kapitus.com</a>.</p>
          <p>All User Content shall comply with these Terms of Use. In addition, you specifically
            represent that: (i) you own or have the necessary licenses, rights, consents or
            permissions to use, and authorize Kapitus to use, all patent, copyright, trade
            secret, trademark, and other proprietary rights to enable inclusion and use of User
            Content in the manner contemplated by the Sites and these Terms of Use; and (ii)
            you have the appropriate and necessary written consent, release, or permission of
            each identifiable individual person or business in your User Content to use the
            name, likeness, or other personal characteristics of each such identifiable individual
            or business (as applicable) to enable inclusion and use of such User Content in the
            manner contemplated by the Sites and these Terms of Use. You are solely
            responsible for any User Content you submit or post and the consequences of
            submitting or posting it.</p>
          <p>By submitting or posting User Content, you grant Kapitus a non-exclusive,
            irrevocable, perpetual, royalty-free, sub-licensable, and transferable license to use,
            reproduce, distribute, display, modify, prepare derivative works based upon, and
            otherwise exploit (including, but not limited to, over the Internet, social media,
            broadcast television, radio or any other uses or media), the User Content to the
            maximum extent permitted by applicable law. You also hereby grant each user of
            the Sites a non-exclusive license to access your User Content through the Sites,
            and to use, access, reproduce, distribute, transmit, forward, display, and perform
            such User Content to the extent permitted by the Sites under these Terms of Use.</p>
          
          
          <h6>6. THIRD-PARTY CONTENT</h6>
          <p>Kapitus may link to other websites that we believe may be useful to you, provide
            information about third-party products, services, or events, or allow third parties to
            make their content and information available on or through our Sites (collectively
            “Third-Party Content”). Kapitus does not control or endorse, and makes no
            representations or warranties regarding, any Third-Party Content. Your access to
            and use of such Third-Party Content is at your own risk and Kapitus assumes no
            liability for such use. Your dealings and correspondence with third parties and your
            use of, or interaction with, any Third-Party Content is solely between you and the
            third party. When leaving our Sites, you should be aware that these Terms of Use
            no longer govern, and therefore, you should review the applicable terms and
            policies, including privacy and data gathering practices, of any third-party websites.</p>
          
          
          <h6>7. COPYRIGHT COMPLAINTS</h6>
          <p>We respect copyright ownership and expect users of our Sites to do so as well. It is
            our goal to limit or prevent access to the Sites by any users who are repeat
            infringers of copyright.</p>
          <p>If you are a copyright owner or an agent thereof and believe anything on our Sites
            infringes upon your copyrights, you may submit a notification of claimed
            infringement under the Digital Millennium Copyright Act (“DMCA”) by providing
            notice to Kapitus through our designated agent, by email or mail, as follows:</p>
          <p class="terms-contacts">Kapitus<br>
            ATTN: Director of Marketing & Communications<br>
            120 W. 45th Street, 4th Floor<br>
            New York, NY 10036<br>
            <a target="_blank" rel="noopener" href="mailto:ipcompliance@kapitus.com">ipcompliance@kapitus.com</a></p>
          <p>Your DMCA notice should comply with 17 U.S.C. § 512(c)(3) and include
            substantially the following information:</p>
          <p>(i) A physical or electronic signature of a person authorized to act on behalf
            of the owner of an exclusive right that is allegedly being infringed;</p>
          <p>(ii) Identification of the copyrighted work claimed to have been infringed, or,
            if multiple copyrighted works at a single online site are covered by a single
            notification, a representative list of such works on that site;</p>
          <p>(iii) Identification of the material that is claimed to be infringing or to be the
            subject of infringing activity and that is to be removed or access to which is
            to be disabled, and information reasonably sufficient to permit the service
            provider to locate the material;</p>
          <p>(iv) Information reasonably sufficient to permit the service provider to
            contact the complaining party, such as an address, telephone number, and, if
            available, an electronic mail address at which the complaining party may be
            contacted;</p>
          <p>(v) A statement that the complaining party has a good faith belief that use of
            the material in the manner complained of is not authorized by the copyright
            owner, its agent, or the law;</p>
          <p>(vi) A statement that the information in the notification is accurate, and
            under penalty of perjury, that the complaining party is authorized to act on
            behalf of the owner of an exclusive right that is allegedly infringed.</p>
          <p>You should review the DMCA and/or consult an attorney before submitting a DMCA
            notice. You acknowledge that if you fail to comply with substantially all of the
            requirements of a DMCA notice, it may not be valid, and we may not be able to
            remove infringing content.</p>
          
          
          <h6>8. TELEPHONE COMMUNICATIONS</h6>
          <p>By your use of the Sites, such as, without limitation, through a submission of a
            request for commercial financing, you expressly consent to receiving servicing,
            collection, marketing, and other calls and messages, including auto-dialed and pre-recorded
            message calls, and SMS messages (including text messages) from us, our
            affiliates, our marketing partners, our trusted third-party providers, referral
            provides, and marketplace participants, at any telephone numbers that you have
            provided or may provide in the future (including any cellular telephone numbers).
            Your consent will be effective even if the number you have provided is registered on
            any state or federal Do-Not-Call (DNC) list. You understand that you are not
            required to provide consent to telemarketing calls as a condition of receiving any
            financing or services from Kapitus. Please note that you are not required to
            consent to be called for marketing or promotional purposes in order to qualify for
            financing or obtain any other products or services from Kapitus. If you do not
            agree to be called for marketing or promotional purposes, please call (844) 547-9396
            or email <a target="_blank" rel="noopener" href="mailto:DNC@kapitus.com">DNC@kapitus.com</a>. In your request, please specify whether you
            would like to stop receiving SMS messages (including text messages), telephone
            calls, or both, and the telephone number(s) for which you are making the request.</p>
          <p>You also consent to the recording and monitoring, for quality assurance, training,
            risk management, collection, or other purposes, of any call that you place with us
            (or our agents, representatives, affiliates, third parties or anyone calling on our
            behalf) or that we (or our agents, representatives, affiliates, third parties or anyone
            calling on our behalf) place to you.</p>
          
          
          <h6>9. DISCLAIMERS</h6>
          <p class="fw-600">THE SITES (INCLUDING SITE CONTENT, USER CONTENT, SERVICES, AND
            WEBSITES LINKED TO THE SITE) ARE MADE AVAILABLE “AS IS,” “AT YOUR
            OWN RISK,” AND “AS AVAILABLE,” WITHOUT WARRANTY OF ANY KIND,
            EITHER EXPRESS OR IMPLIED INCLUDING, WITHOUT LIMITATION,
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, ACCURACY, NON-INFRINGEMENT OR
            ENJOYMENT. WE MAKE NO GUARANTEE THAT THE SITES ARE UP-TO-DATE,
            ACCURATE, OR COMPLETE, AND YOU SHOULD NOT RELY ON THEM FOR ANY
            DECISION OR TO TAKE ANY ACTION. KAPITUS DOES NOT WARRANT THAT
            THE SITES WILL MEET THE USER’S REQUIREMENTS AND/OR ARE FREE OF
            INTERRUPTION OR ERRORS, OR THAT ANY OF THE SITES IS FREE OF
            VIRUSES, WORMS, BUGS, OR OTHER MALICIOUS TECHNOLOGY.</p>
          
          
          <h6>10. LIMITATION OF LIABILITY</h6>
          <p class="fw-600">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, KAPITUS
            (INCLUDING ITS SUBSIDIARIES AND AFFILIATES) SHALL NOT BE LIABLE
            TO YOU OR ANY OTHER PERSON FOR ANY DAMAGES WHATSOEVER
            (INCLUDING ANY DIRECT, SPECIAL, EXEMPLARY, INDIRECT, INCIDENTAL,
            CONSEQUENTIAL OR PUNITIVE DAMAGES, COSTS OR ATTORNEY’S FEES)
            ARISING OUT OF, OR RELATING TO, THESE TERMS OF USE, ACCESS TO, USE
            OF OR THE OPERATION OF ANY SITE, ANY OF THE SITE CONTENT OR USER
            CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY
            OTHER LEGAL THEORY.</p>
          
          
          <h6>11. INDEMNIFICATION</h6>
          <p>To the fullest extent permitted by applicable law, you agree to indemnify, defend,
            and hold harmless Kapitus (including any of its subsidiaries, affiliates, licensors and
            their respective officers, directors, employees, agents and representatives) from
            and against all claims, actions, liabilities, losses, demands, damages, expenses, and
            costs (including attorney’s fees) (collectively the “Claims”) arising out of or relating
            to: (i) your access to or use of the Sites or Services; (ii) your violation,
            misappropriation, or infringement of any rights of another (including intellectual
            property rights or privacy rights); and (iii) your breach of any of these Terms of
            Use. You shall promptly notify Kapitus of any third-party Claims, cooperate with
            Kapitus in defending such Claims, and pay all fees, costs, and expenses associated
            with defending such Claims (including, but not limited to, attorneys’ fees). Kapitus
            reserves the right to assume, at its sole discretion, the exclusive control over
            defense or settlement of any third-party Claims.</p>
          
          
          <h6 id="arbitrationNotice">12. ARBITRATION; JURY TRIAL WAIVER; CLASS ACTION WAIVER</h6>
          <p class="fw-600">PLEASE READ THIS SECTION CAREFULLY BECAUSE IT (a) MAY REQUIRE
            YOU TO ARBITRATE DISPUTES WITH KAPITUS; (b) PRECLUDES YOU FROM
            HAVING A JURY TRIAL; AND (c) REQUIRES THAT ANY DISPUTES BE
            BROUGHT IN YOUR INDIVIDUAL CAPACITY ONLY AND NOT AS A CLASS
            ACTION OR ANY OTHER TYPE OF REPRESENTATIVE PROCEEDING.</p>
          <p class="terms-point">a. <span class="fw-600">ARBITRATION. IF ANY DISPUTE, CLAIM, OR CONTROVERSY
            ARISES BETWEEN YOU AND KAPITUS (INCLUDING ITS SUBSIDIARIES AND
            AFFILIATES) ARISING OUT OF AND/OR IN ANY WAY RELATED TO (i) THE
            ACCESS OR USE OF THE SITES, (iii) THESE TERMS OF USE, AND/OR (iii)
            THE ARBITRABILITY OF ANY OF SUCH CLAIMS, DISPUTES, AND/OR
            CONTROVERSIES—WHETHER SOUNDING IN CONTRACT, TORT, OR
            OTHERWISE (COLLECTIVELY, “DISPUTES”) — EITHER PARTY MAY ELECT
            (BUT IS NOT REQUIRED) TO SUBMIT THE DISPUTE TO BINDING
            ARBITRATION BEFORE THE AMERICAN ARBITRATION ASSOCIATION (THE
            “AAA”) BY SUBMITTING THE REQUIRED AAA FORMS AND FILING FEES TO
            THE AAA. IF ARBITRATION IS SELECTED BY ANY PARTY, NO PARTY SHALL
            HAVE THE RIGHT TO LITIGATE ANY DISPUTES IN COURT, HAVE A JURY
            TRIAL, OR INITIATE OR PARTICIPATE IN A CLASS ACTION. ALL DISPUTES
            SHALL BE GOVERNED BY, AND CONSTRUED IN ACCORDANCE WITH, THE
            FEDERAL ARBITRATION ACT, AND SHALL BE INTERPRETED IN THE
            BROADEST WAY THAT LAW WILL ALLOW.</span></p>
          <p class="terms-point"><span class="fw-600">Arbitration Procedures.</span> 
            All Disputes submitted to the AAA will be resolved
            through confidential, binding arbitration before a single arbitrator agreed to by the
            parties, and shall be conducted in accordance with this provision and AAA
            Commercial Arbitration Rules in effect when the claim is filed, except where those
            rules conflict with this arbitration provision. The most recent version of the AAA
            Commercial Arbitration Rules is available on the <a target="_blank" rel="noopener" href="https://www.adr.org/sites/default/files/CommercialRules_Web-Final.pdf">AAA website</a>. In arbitration, either
            party may choose to participate in any hearing by phone or other electronic means
            and/or be represented by counsel. Notwithstanding the foregoing, any in-person
            hearings shall be conducted in Arlington, Commonwealth of Virginia. The arbitrator
            shall apply the substantive laws of the Commonwealth of Virginia, without regard to
            any applicable principals of conflicts of law. The arbitrator shall make any award in
            writing and, if requested by either party, shall include a reasoned opinion for the
            award. Except as may be required by law, neither a party nor the arbitrator may
            disclose the existence, content, or results of any arbitration without the prior
            written consent of both parties, unless to protect or pursue a legal right.</p>
          <p class="terms-point"><span class="fw-600">Arbitration Limits.</span> 
            All Disputes (including claims brought as part of a class
            action, private attorney general or other representative action) can be arbitrated
            only on an individual basis. The arbitrator has no authority to arbitrate any Dispute
            on a class or representative basis and may award relief only on an individual basis.
            The arbitrator shall have no authority to award punitive damages, consequential
            damages, or other damages not measured by the prevailing party’s actual
            damages, except as required by statute or allowed under any agreement between
            the parties.</p>
          <p class="terms-point"><span class="fw-600">Paying for Arbitration Fees.</span> 
            Arbitration fees will be allocated according to
            the applicable AAA Rules. All parties are responsible for their own attorney’s fees,
            expert fees, and any other expenses unless the arbitrator awards such fees or
            expenses to a prevailing party based on a contract between the parties or
            applicable law.</p>
          <p class="terms-point"><span class="fw-600">The Final Award.</span>
            Any award rendered by the arbitrator shall be final, and
            binding on the parties, and may be entered and enforced in any court having
            jurisdiction, and any court where a party or its assets is located (to which
            jurisdiction the parties consent for the purposes of enforcing such award), unless a
            party appeals such award in writing to the AAA within 30 days of notice of the
            award pursuant to the AAA’s Optional Appellate Arbitration Rules. The arbitration
            appeal shall be determined by a panel of three arbitrators. The panel will consider
            all facts and legal issues anew based on the same evidence presented in the prior
            arbitration and will make decisions based on a majority vote. Arbitration fees for
            the arbitration appeal shall be allocated according to the applicable AAA Rules. All
            parties are responsible for their own attorney’s fees, expert fees, and any other
            expenses unless the panel awards such fees or expenses to the prevailing party
            based on a contract between the parties or applicable law. An award by a panel on
            appeal is final. A final award is subject to judicial review as provided by applicable
            law.</p>
          <p class="terms-point">b. <span class="fw-600">JURY TRIAL WAIVER. TO THE EXTENT PERMITTED BY
            APPLICABLE LAW, YOU AND KAPITUS EACH HEREBY WAIVE TRIAL BY JURY
            IN ANY COURT IN ANY SUIT, ACTION, OR PROCEEDING ON ANY MATTER
            ARISING OUT OF AND/OR IN ANY WAY RELATED TO THE ACCESS OR USE
            OF THE SITES OR THESE TERMS OF USE, WHETHER SOUNDING IN
            CONTRACT, TORT, OR OTHERWISE.</span> 
            The parties agree that a copy of this
            paragraph may be filed as written evidence of the knowing and voluntary
            agreement among the parties to irrevocably waive their right to trial by jury in any
            proceeding whatsoever between them relating to the use of the Sites or these
            Terms of Use.</p>
          <p class="terms-point">c. <span class="fw-600">CLASS ACTION WAIVER. YOU AND KAPITUS EACH HEREBY
            AGREE THAT THE RESOLUTION OF ANY DISPUTES BETWEEN THE PARTIES
            SHALL BE CONDUCTED IN THEIR INDIVIDUAL CAPACITIES ONLY, AND
            EACH PARTY EXPRESSLY WAIVES ANY RIGHT TO ASSERT ANY CLAIMS
            AGAINST THE OTHER PARTY AS A REPRESENTATIVE OR MEMBER IN ANY
            CLASS ACTION OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND,
            EXCEPT WHERE SUCH WAIVER IS PROHIBITED BY LAW.</span></p>
          
          
          <h6>13. GOVERNING LAW AND JURISDICTION</h6>
          <p>By accessing or using the Sites, you agree that any claim, dispute, or controversy
            (whether in contract, tort, or otherwise) at any time arising out of and/or in any
            way related to the access or use of the Sites or these Terms of Use is governed by,
            and will be construed in accordance with, the laws of the Commonwealth of Virginia
            (to the extent not preempted by federal law), without regard to internal principles
            of conflict of laws. You further agree that the sole and exclusive venue shall be a
            court located either in Arlington or Alexandria, Commonwealth of Virginia.</p>
          
          
          <h6>14. MISCELLANEOUS</h6>
          <p class="terms-point">a. Entire Agreement. These terms and any and all language directly
            linked to or within these terms constitute the entire agreement between you and
            Kapitus relating to the subject matter of these Terms of Use, and these Terms of
            Use supersede all prior or contemporaneous communications or proposals relating
            to the subject matter of these Terms of Use.</p>
          <p class="terms-point">b. Severability. In case any part of these Terms of Use is found to be
            invalid, void, or unenforceable in any respect, the validity, legality, and
            enforceability of any other term contained therein or herein shall not in any way be
            affected or impaired and shall remain in full force and effect.</p>
          <p class="terms-point">c. Assignment. These Terms of Use, and any rights and licenses granted
            hereunder, may not be transferred or assigned by you, but they may be assigned
            by Kapitus without restrictions or limitations. Any attempted transfer or
            assignment in violation hereof shall be null and void.</p>
          <p class="terms-point">d. Waiver. Our failure to enforce any part of these Terms of Use shall not
            be deemed a waiver of any further rights hereunder, and does not mean we give up
            the right to later enforce that part or any other part.</p>
          <p class="terms-point">e. No Interpretation of Caption or Headings. The captions and headings
            within these Terms of Use are for ease of reference only and are not intended to
            create any substantive meaning or to modify the terms or clauses either following
            them or contained in any other provisions herein.</p>
          
          
          <h6>15. PRIVACY POLICY</h6>
          <p>In addition to reviewing these Terms of Use, you should also review our Privacy
            Policy to better understand how we collect and use your personal information. Your
            use of the Sites constitutes your agreement to the terms and conditions of our
            Privacy Policy. To access our Privacy Policy, <a target="_blank" rel="noopener" href="https://kapitus.com/privacy-policy/">click here</a>.</p>
          
          
          <h6>16. CONTACT INFORMATION</h6>
          <p>If you have any questions or concerns about these Terms, please contact us at <a target="_blank" rel="noopener" href="mailto:support@kapitus.com">support@kapitus.com</a>.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommissionNote',
  data() {
    return {
    }
  },
}
</script>
