<template>
  <div class="btn-toolbar dashboard-navigation" role="toolbar">
    <div class="navigation-part">
      <div class="btn-group mr-3 pc-only" role="group">
        <router-link :to="'/dashboard' + getFiltersFromUrl()" :class="['btn', 'kap-btn-primary big-button v-align-middle', dashboardActive]">
          <font-awesome-icon :icon="['fas', 'list']"/>
          &nbsp;Dashboard
        </router-link>
      </div>
      <div class="btn-group mr-3 " role="group">
        <div class="dropdown">
          <button class="btn kap-btn-outline-primary dropdown-toggle big-button-show-by-status" :class="[funnelStageActive]"
                  type="button" id="funnelStageMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Show by Status
          </button>
          <div id="dropdown-menu" class="dropdown-menu" aria-labelledby="funnelStageMenu" x-placement="bottom-start">
            <button class="dropdown-item" v-for="(item, index) in menuItems" v-on:click.stop="changePage(index)" v-bind:key="index">
              {{ item.title }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-bind:class="'dates-line ' + (shortMode === true ? 'form-inline m-042 d-none' : 'form-inline m-042')">
      <div class="mr-3">
        <label class="filter-labels">From</label>
        <datepicker
            :wrapper-class="(filters.calendarDisabled?'disabled':'')+' dashboard-picker'"
            :disabled-dates="disabledDates.dateStart"
            :disabled="filters.calendarDisabled"
            v-model="filters.dateStart"
            format="MM/dd/yyyy"
            :use-utc=false
            id="dateStart">
          <template v-slot:belowDate>
            <slot name="belowDate"><span></span></slot>
          </template>
        </datepicker>
      </div>
      <div class="mr-2">
        <label class="filter-labels">To</label>
        <datepicker
            :wrapper-class="(filters.calendarDisabled?'disabled':'')+' dashboard-picker'"
            :disabled-dates="disabledDates.dateEnd"
            :disabled="filters.calendarDisabled"
            v-model="filters.dateEnd"
            format="MM/dd/yyyy"
            :use-utc=false
            id="dateEnd">
          <template v-slot:belowDate>
            <slot name="beforeCalendarHeader"><span></span></slot>
          </template>
        </datepicker>
      </div>
    </div>
    <div v-bind:class="(shortMode === true ? 'd-none' : 'form-inline navigation-part ml-3 mt-3')">
      <div class="input-group all-time">
        <label class="checkbox-all">All Time
          <input type="checkbox" autocomplete="off" value="1" id="allTime" v-model="allTime" v-on:change="changeAllTime">
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="filter-reset ">
        <span class="pl-2 reset-all-link v-align-middle " v-bind:class="{ 'disabled': isResetFiltersDisabled }" v-on:click="resetFilters" id="resetFilters">RESET ALL FILTERS</span>
      </div>
    </div>
    <div class="btn-group ml-auto navigation-part pc-only" role="group">
      <router-link to="/submission/new" :class="['btn', 'big-button kap-button navigation-button', newReferralActive]">
        New Submission
      </router-link>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs3-datepicker';
import {each, map} from 'underscore';
import {Dictionary} from "@/constants/dictionary";
import moment from 'moment';
import utils from "../utils/";

export default {
  name: 'DashboardNavigation',
  components: {
    Datepicker
  },
  emits: ['filters-changed'],
  props: {
    shortMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const initialFilters = this.getInitialFilters();
    return {
      dashboardActive: '',
      funnelStageActive: '',
      newReferralActive: '',
      menuItems: Dictionary.submission_statuses,
      resetFilterDisabled: false,
      isResetFiltersDisabled: "disabled",
      disabledDates: {
        dateStart: {
          from: '',
        },
        dateEnd: {
          to: '',
        }
      },
      allTime: initialFilters.calendarDisabled,
      filters: {
        ...initialFilters
      },
    }
  },
  mounted() {
    this.triggerFiltersChanged();
  },
  watch: {
    'filters': {
      handler: function () {
        this.triggerFiltersChanged();
        this.updateUrlQuery();
      },
      deep: true
    },
  },
  methods: {
    changePage: function (i) {
      this.$router.push(this.menuItems[i]['href'] + this.getUrlFilterParameters());
    },
    getUrlFilterParameters() {
      const startDate = this.getDateForFilter(this.filters.dateStart);
      const endDate = this.getDateForFilter(this.filters.dateEnd);
      const searchTerm = this.$route.query.term ? `&term=${this.$route.query.term}` : '';

      return `&dateStart=${startDate}&dateEnd=${endDate}&allTime=${this.filters.calendarDisabled}${searchTerm}`;
    },
    getDateForFilter(date) {
      const month = `${(date.getMonth() + 1)}`.padStart(2, '0');
      const day = `${(date.getDate())}`.padStart(2, '0');

      return `${date.getFullYear()}-${month}-${day}`;
    },
    initFilters: function () {
      this.filters = {
        dateStart: this.getMonthBeforeStartDate(),
        dateEnd: this.getTodayEndDate()
      };
      this.allTime = false;
    },
    getInitialFilters() {
      let dateStart = null;
      let dateEnd = null;
      let allTime = 'false';

      if (typeof this.$route.query !== 'undefined' && typeof this.$route.query.dateStart !== 'undefined') {
        dateStart = this.getDateFromString(this.$route.query.dateStart + ' 00:00:00');
      }

      if (typeof this.$route.query !== 'undefined' && typeof this.$route.query.dateEnd !== 'undefined') {
        dateEnd = this.getDateFromString(this.$route.query.dateEnd + ' 23:59:59');
      }

      if (typeof this.$route.query !== 'undefined' && typeof this.$route.query.allTime !== 'undefined') {
        allTime = this.$route.query.allTime;
      }

      if (allTime === 'true' && (!dateStart || !dateEnd)) {
        return this.getAllTimeFilters();
      }

      return {
        dateStart: !dateStart ? this.getMonthBeforeStartDate() : dateStart,
        dateEnd: !dateEnd ? this.getTodayEndDate() : dateEnd,
        calendarDisabled: allTime === 'true'
      };
    },
    triggerFiltersChanged: function () {
      this.updateDisableDates();
      this.setVisibility();
      this.$emit('filters-changed', this.filters);
    },
    updateUrlQuery: function () {
      if (this.$route?.query) {
        const query = {
          ...this.$route.query,
          dateStart: this.getDateForFilter(this.filters.dateStart),
          dateEnd: this.getDateForFilter(this.filters.dateEnd),
          allTime: this.allTime
        };
        this.$router?.replace({query});
      }
    },
    changeAllTime: function () {
      if (this.filters && this.filters.calendarDisabled) {
        this.filters = {
          dateStart: this.getMonthBeforeStartDate(),
          dateEnd: this.getTodayEndDate(),
          calendarDisabled: false
        };
      } else {
        this.filters = this.getAllTimeFilters();
      }
    },
    resetFilters: function () {
      this.filters = map(() => false);
      this.initFilters();
    },
    setVisibility: function () {
      this.resetFilterDisabled = !(this.filters.dateStart || this.filters.dateEnd || this.allTime);

      const self = this;
      this.isResetFiltersDisabled = 'disabled';
      each(this.filters, function (key) {
        if (self.filters[key] && key !== 'dateStart' && key !== 'dateEnd') {
          self.isResetFiltersDisabled = '';
        }
      });

      const today = new Date();
      if ((new Date(today.getFullYear(), today.getMonth(), 1).getTime() !== this.filters.dateStart?.getTime()) ||
          (new Date(today.getFullYear(), today.getMonth() + 1, 0).getTime() !== this.filters.dateEnd?.getTime())
      ) {
        self.isResetFiltersDisabled = '';
      }
    },
    getDateFromString(stringDate) {
      if (moment(stringDate).isValid()) {
        return new Date(moment(stringDate).format());
      }
      return null;
    },
    getMonthBeforeStartDate() {
      return new Date(moment().add(-30, 'days').startOf('day').format());
    },
    getTodayEndDate() {
      return new Date(moment().endOf('day').format());
    },
    updateDisableDates: function () {
      this.disabledDates.dateStart = {from: this.filters.dateEnd};
      this.disabledDates.dateEnd = {to: this.filters.dateStart};
    },
    getFiltersFromUrl: function () {
      return utils.getFiltersFromUrl(this.$route.query);
    },
    getAllTimeFilters() {
      const today = this.getTodayEndDate();

      return {
        dateStart: this.getDateFromString('2006-01-02 00:00:00'),
        dateEnd: today,
        calendarDisabled: true
      };
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 992px) {
  .navigation-part {
    padding-top: .5rem !important;
  }
}

.filter-labels {
  font-size: 12px;
  padding: 0 0 0 10px;
  text-align: left;
  justify-content: left;
  font-family: Lato, sans-serif;
}
</style>
