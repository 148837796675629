<template>
  <nav class="navbar navbar-dark navbar-expand-xl kap-navbar sticky-top">
    <div class="container">
      <router-link to="/dashboard" :class="['navbar-brand']">
        <img src="/img/KapitusR_Logo.png" class="d-inline-block align-top kap-header-logo" alt="">
      </router-link>
      <button id="navbarToggler" class="navbar-toggler" type="button" data-toggle="collapse"
              data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <form class="form-inline my-2 my-xl-0 w-35" @submit="directToSearchPage" id="search-form">
          <div class="input-group  vue3-input-350">
            <input class="form-control" placeholder="Search..." aria-label="Search..." id="search-input" name="search-input" ref="searchTerm"/>
            <span class="my-2 my-sm-0 pt-2 mr-2 search-icon" @click="directToSearchPage">
              <font-awesome-icon :icon="['fas', 'search']"/>
            </span>
          </div>
        </form>
        <HeaderPartnerManager/>
        <ul class="navbar-nav ml-auto" id="navigation">
          <li class="nav-item mobile-only">
            <router-link @click="closeHeaderMenu" :to="'/dashboard' + getFiltersFromUrl()" :class="['nav-link']">Dashboard</router-link>
          </li>
          <li class="nav-item mobile-only">
            <router-link @click="closeHeaderMenu" :to="'/submission/new'" :class="['nav-link']">New Submission</router-link>
          </li>
          <li class="nav-item">
            <router-link @click="closeHeaderMenu" :to="{path: '/partner/'+(currentUser ? currentUser.custom_partner_uuid : '')}" :class="['nav-link']">My Account</router-link>
          </li>
          <li class="nav-item">
            <router-link @click="closeHeaderMenu" to="/logout" :class="['nav-link']">Log Out</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import HeaderPartnerManager from "./HeaderPartnerManager";
import utils from "../../utils/";

export default {
  name: 'HeaderLayout',
  components: {HeaderPartnerManager},
  computed: {
    currentUser() {
      return this.$store.state.auth.userData;
    }
  },
  data() {
    return {
      items: [],
      debounceTime: 500,
      maxNumberOfItems: 20,
    };
  },
  created() {
    this.$watch(
        () => this.$route.path,
        (to) => {
          if (to !== '/submissions' && this.$refs['searchTerm'] && this.$refs['searchTerm'].value) {
            this.$refs['searchTerm'].value = '';
          }
        }
    )
  },
  methods: {
    closeHeaderMenu() {
      window.scrollTo(0, 0);
      if (document.getElementById('navbarSupportedContent')?.classList.contains("show")) {
        document.getElementById('navbarToggler').click();
      }
    },
    getFiltersFromUrl: function () {
      return utils.getFiltersFromUrl(this.$route.query);
    },
    directToSearchPage: function (ev) {
      ev.preventDefault();
      this.closeHeaderMenu();
      this.$router.push({name: 'Submissions', query: {"term": this.$refs['searchTerm'].value, "allTime": true}});
    }
  }
};
</script>
