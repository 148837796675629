import AuthService from '../services/auth.service';
import storage from "../plugins/storage";

const userData = storage.getItemFromJson('userData');

const initialState = userData && userData.access_token
    ? {status: {loggedIn: true}, userData, cognitoUser: null}
    : {status: {loggedIn: false}, userData: null, cognitoUser: null};

function resetState(state) {
    state.status.loggedIn = false;
    state.userData = null;
    state.cognitoUser = null;
}

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({commit}, user) {
            return AuthService.login(user).then(
                authorizedUser => {
                    storage.setItemAsJson('userData', authorizedUser);
                    commit('loginSuccess', authorizedUser);
                    return Promise.resolve(authorizedUser);
                },
                error => {
                    if (error.userAttributes) {
                        storage.setItemAsJson('userAttributes', error.userAttributes);
                    }
                    commit('logout');
                    return Promise.reject(error);
                }
            );
        },
        logout({commit}) {
            storage.clear();
            commit('logout');
        },
        command(_, {user, commandName}) {
            return AuthService[commandName] ? AuthService[commandName](user) : Promise.reject();
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.userData = user;
        },
        logout(state) {
            resetState(state)
        },
        cognitoUserCreated(state, cognitoUser) {
            state.cognitoUser = cognitoUser;
        },
    }
};
