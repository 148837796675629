import axios from "axios";

export default {
    setAuthenticatedInterceptor(token) {
        axios.interceptors.request.use(function (requestConfig) {
            requestConfig.headers['Content-type'] = 'application/json';
            requestConfig.headers['Accept'] = 'application/json';
            if (token) {
                requestConfig.headers['Token'] = token;
            }
            return requestConfig;
        });
    },
    resetInterceptors() {
        axios.interceptors.request.handlers = [];
    }
}
