<template>
  <div class="container-lg kap-container">
    <div class="content">
      <h2>Nothing Found</h2>
      <div>Sorry, the content you are looking for is not available.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>
