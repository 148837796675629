export const ApplicationModel = {
    uuid: null,
    partner_uuid: null,
    type: null,
    lead_type: null,
    requested_funding: null,
    requested_term: null,
    requested_structure: null,
    use_of_funds: null,
    urgency: null,
    industry: null,
    offer_link: null,
    submission_secret: null,
    extra: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
    product_type: null,
    funded_amount: null,
    funded_date: null,
    financing_provider: null,
    sale_type: null,
    commission_amount: null,
    documents: [],
    notifications: [],
    owners: [],
    business: {},
    partner: {},
    kapitus_application_status: null,
    resume_link: null,
    submission_origin: null
};
