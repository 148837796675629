<template>
  <div class="form-group">
    <label :for="id">{{ label }}</label>
    <Field :name="id"
           :type="type ? type : 'text'"
           class="form-control company-color"
           :id="id"
           v-model="inputValue"
           :placeholder="placeholder ? placeholder : ''"/>
    <ErrorMessage :name="id" class="error-feedback"/>
    <span v-if="validationError" class="text-danger">{{ validationError }}</span>
  </div>
</template>

<script>
import {ErrorMessage, Field} from "vee-validate";

export default {
  props: ['id', 'validationError', 'modelValue', 'label', 'placeholder', 'type', 'error'],
  name: "TextField",
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  components: {
    Field,
    ErrorMessage,
  }
}
</script>
