<template>
  <div class="container-fluid h-100 login">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-3 text-center">
        <img src="/img/KapitusR_Logo.png" class="logo mw-100" alt="kapitus logo">
      </div>
    </div>

    <div class="row justify-content-center text-center mt-4 mb-4">
      <div class="col-md-12 col-lg-3 ">

        <div class="title title-secondary fs-19">
          Referral Provider Login
        </div>
      </div>
    </div>

    <div v-if="message" class="v-align-middle error-feedback p-2" role="alert">
      {{ message }}
    </div>

    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-3">
        <Form @submit="handleLogin" :validation-schema="schema">
          <div class="form-group">
            <label for="username">Email</label>
            <Field name="username" type="email" class="form-control" placeholder="Email" id="username"/>
            <ErrorMessage name="username" class="error-feedback" id="username-error"/>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <Field name="password" type="password" class="form-control" placeholder="Password" id="password"/>
            <ErrorMessage name="password" class="error-feedback" id="password-error"/>
          </div>

          <div>
            <button class="kap-btn-primary-inverted mt-3 mb-3 btn big-button-100pct navigation-button" :disabled="loading" type="submit">
              <span v-show="loading" class="spinner-border spinner-border-sm "></span>
              <span class="fs-18">&nbsp;Log In</span>
            </button>
          </div>
          <div class="row justify-content-center text-center mb-3">
            <router-link to="/password/remind" class="white-link text-center fw-600">Forgot Password?</router-link>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import * as yup from "yup";
import ErrorCodes from "../constants/error-codes";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!").email("Email must be valid"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user)
          .then(() => {
                this.$gtag.config({user_id: user.username})
                this.$router.push("/dashboard");
              }
          )
          .catch((error) => {
            this.loading = false;

            if (error.code === ErrorCodes.COGNITO_REQUIRES_REGISTRATION) {
              this.$router.push("/password/reset");
              return;
            }
            if (error.code && error.code === 8001) {
              this.message = 'Email and password combination is incorrect. Please try again';
            } else {
              this.message = (error.code ? `Error ${error.code}: ` : '') + (error.message || error.toString());
            }
          });
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}
</style>
