<template>
  <div v-bind:class="(displayHeader) ? 'wrapper':'wrapper wrapper-login-bg'">
  <Header v-if="displayHeader"></Header>
  <router-view :key="$route.fullPath"/>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from '@/components/Header/HeaderLayout.vue'
import Footer from '@/components/Footer.vue'


export default {
  components: {
    Header, Footer
  },
  computed: {
    displayHeader() {
      return this.$route.meta.hideHeader ? false : true;
    },
  }
};
</script>
