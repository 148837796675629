<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-6 footer-column text-left">
          © <span class="copyright-text">Copyright</span> {{ new Date().getFullYear() }} <span>•</span>
          <a href="https://kapitus.com/">Kapitus&trade;</a> <span>•</span> All Rights Reserved
        </div>
        <div class="col-md-12 col-lg-6 footer-column text-right">
          <a href="https://kapitus.com/terms-conditions/">Terms &amp; Conditions</a> <span>|</span> <a href="https://kapitus.com/privacy-policy/">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

