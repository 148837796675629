<template>
  <div class="form-group">
    <label :for="id">{{ label }}</label>
    <select class="form-control company-color" :name="id" :id="id" @change="onChange">
      <option v-for="(item, index) in options" v-bind:key="index" :value="item.value" :selected="this.modelValue === item.value">{{ item.text }}</option>
    </select>
    <ErrorMessage :name="id" class="error-feedback"/>
    <span v-if="validationError" class="error-feedback">{{ validationError }}</span>
  </div>
</template>

<script>
import {ErrorMessage} from "vee-validate";

export default {
  props: ['id', 'validationError', 'modelValue', 'label', 'options', 'error'],
  name: "TextField",
  methods: {
    onChange(ev) {
      this.$emit('update:modelValue', ev.target.value);
    }
  },
  components: {
    ErrorMessage,
  }
}
</script>
