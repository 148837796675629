<template>
  <div></div>
</template>

<script>
export default {
  name: 'Logout',
  mounted() {
    this.$store.dispatch('auth/logout');
    this.$router.push('/login');
  }
};
</script>
