<template>
  <div class="container-fluid h-100 login">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-3 text-center">
        <img src="/img/KapitusR_Logo.png" class="logo mw-100" alt="kapitus logo">
      </div>
    </div>

    <div class="row justify-content-center text-center mt-4 mb-4">
      <div class="col-md-12 col-lg-3">
        <h4 class="title">Complete registration</h4>
      </div>
    </div>

    <div class="row justify-content-center mb-3">
      <div class="col-md-12 col-lg-3">
        <SetPassword @password-set="handlePasswordReset"
                     @password-change-canceled="handlePasswordChangeCanceled"
                     :username="(userAttributes?userAttributes.email:null)"
                     :error="error"
                     :success-message="successMessage"
                     secondaryButtonStyle="kap-btn-outline-primary-inverted"
                     primaryButtonStyle="kap-btn-primary-inverted"
        ></SetPassword>
      </div>
    </div>
  </div>
</template>

<script>
import SetPassword from './SetPassword';
import storage from "../../plugins/storage";

export default {
  name: 'ResetPassword',
  components: {
    SetPassword
  },
  data() {
    return {
      error: null,
      successMessage: null,
      userAttributes: storage.getItemFromJson('userAttributes')
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    } else if (!this.$data.userAttributes || !this.userAttributes.email) {
      this.$router.push("/login"); // might need to remove this if we allow users to route directly
    }
  },
  methods: {
    handlePasswordReset(user) {
      this.$data.successMessage = null;
      this.$data.error = null;

      if (this.userAttributes.email !== user.email) {
        user.email = this.userAttributes.email;
      }

      this.$store.dispatch('auth/command', {user, commandName: 'resetPassword'})
          .then(() => {
            this.$router.push('/login');
          })
          .catch((error) => {
            this.$data.error = error;
          });
    },
    handlePasswordChangeCanceled() {
      this.$router.push('/login');
    }
  },
};
</script>

