<template>
  <div class="header-partner-manager" v-if="partner && partner.partner_manager_name">
    <div class="row">
      <div class="col-xl-12 col-lg-3 col-md-4 col-sm-5">
        Your Relationship Manager is:
      </div>
      <div class="col-xl-12 col-lg-9 col-md-8 col-sm-7">
        {{ partner.partner_manager_name }}
        <a :href="`tel:${partner.partner_manager_phone}`" class="pl-2">
          <font-awesome-icon :icon="['fas', 'phone-square']" :title="formatPhoneNumber(partner.partner_manager_phone)"/>
        </a>
        <a :href="`mailto:${partner.partner_manager_email}`" class="pl-2">
          <font-awesome-icon :icon="['fas', 'envelope']" :title="partner.partner_manager_email"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "../../plugins/api";

export default {
  name: "HeaderPartnerManager",
  data() {
    return {
      partner: undefined
    }
  },
  methods: {
    async loadPartnerInfo() {
      if (this.$store.state.auth.userData) {
        const userData = this.$store.state.auth.userData;

        const resp = await apiService().getPartner(userData.custom_partner_uuid);
        this.partner = resp.data;
      }
    },
    formatPhoneNumber: function (phoneNumber) {
      if (!phoneNumber) {
        return '';
      }
      return (phoneNumber + '').replace(/\D/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
  },
  mounted() {
    this.loadPartnerInfo();
  }
}
</script>
