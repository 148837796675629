<template>
  <div class="container-lg kap-container company-color">
    <div class="content">
      <h3 class="page-title">New Submission</h3>
      <hr/>

      <Form @submit="handleOnSubmit" :validation-schema="schema">
        <div class="">
          <h4 class="mt-4 mb-3">Contact information</h4>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="business_name">Business Name</label>
                <Field name="business_name" type="text" class="form-control company-color" id="business_name" v-model="form.business.name"/>
                <ErrorMessage name="business_name" class="error-feedback"/>
                <span v-if="errors['business.name']" class="text-danger">{{ errors['business.name'] }}</span>
                <template v-if="errors?.business && errors?.business?.name">
                  <span class="text-danger">{{ errors.business.name }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="owner_first_name">Owner First Name</label>
                <Field name="owner_first_name" type="text" class="form-control company-color" id="owner_first_name" v-model="form.owners[0].first_name"/>
                <ErrorMessage name="owner_first_name" class="error-feedback"/>
                <span v-if="errors['owners[0].first_name']" class="error-feedback">{{ errors['owners[0].first_name'] }}</span>
                <template v-if="errors?.owners && errors.owners[0]?.first_name">
                  <span class="error-feedback">{{ errors.owners[0].first_name }}</span>
                </template>

              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="owner_last_name">Owner Last Name</label>
                <Field name="owner_last_name" type="text" class="form-control company-color" id="owner_last_name" v-model="form.owners[0].last_name"/>
                <ErrorMessage name="owner_last_name" class="error-feedback"/>
                <span v-if="errors['owners[0].last_name']" class="error-feedback">{{ errors['owners[0].last_name'] }}</span>
                <template v-if="errors?.owners && errors.owners[0]?.last_name">
                  <span class="error-feedback">{{ errors.owners[0].last_name }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="owner_email">Owner Email</label>
                <Field name="owner_email" type="email" class="form-control company-color" id="owner_email" v-model="form.owners[0].email"/>
                <ErrorMessage name="owner_email" class="error-feedback"/>
                <span v-if="errors['owners[0].emails[0].email']" class="error-feedback">{{ errors['owners[0].emails[0].email'] }}</span>
                <template v-if="errors?.owners && errors.owners[0]?.phonenumbers && errors.owners[0].phonenumbers[0]?.number">
                  <span class="error-feedback">{{ errors.owners[0].emails[0].email }}</span>
                </template>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="owner_phone">Owner Phone</label>
                <Field name="owner_phone" type="phone" class="form-control company-color" id="owner_phone" v-model="form.owners[0].phonenumbers[0].number" max-length="10"/>
                <ErrorMessage name="owner_phone" class="error-feedback"/>
                <span v-if="errors['owners[0].phonenumbers[0].number']" class="error-feedback">{{ errors['owners[0].phonenumbers[0].number'] }}</span>
                <template v-if="errors?.owners && errors.owners[0]?.phonenumbers && errors.owners[0].phonenumbers[0]?.number">
                  <span class="error-feedback">{{ errors.owners[0].phonenumbers[0].number }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <button type="submit" class="btn big-button navigation-button" v-bind:disabled="isSaving">
              <div class="spinner-border spinner-border-sm text-info" role="status" v-if="isSaving">
                <span class="sr-only">Loading...</span>
              </div>
              Save and Continue
            </button>
            <a href="/#" class="cancel-link pl-3" v-bind:onclick="handleOnBack" id="cancel-button">Cancel</a>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import {ErrorMessage, Field, Form} from "vee-validate";
import * as yup from "yup";
import utils from "../../utils/"
import apiService from "../../plugins/api";

export default {
  name: "NewSubmissionShort",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data: function () {
    const schema = yup.object().shape({
      owner_email: yup.string().required("Owner Email is required!").email('Owner Email is invalid'),
      owner_phone: yup.string().required("Owner Phone is required!").min(10, 'Owner Phone must contain 10 digits').max(10, 'Owner Phone must contain 10 digits'),
      business_name: yup.string().required("Business Name is required!"),
      owner_first_name: yup.string().required("Owner First Name is required!"),
      owner_last_name: yup.string().required("Owner Last Name is required!"),
    });

    return {
      schema,
      form: {
        business: {
          name: '',
        },
        owners: [
          {
            first_name: '',
            last_name: '',
            phonenumbers: [
              {
                number: '',
                type: "cell"
              }
            ],
            email: '',
          }
        ],
        extra: {
          'source': 'rpp'
        }
      },
      errors: {},
      isSaving: false,
    }
  },

  methods: {
    handleOnSubmit() {
      this.errors = {};
      this.isSaving = true;
      const data = JSON.parse(JSON.stringify(this.form));

      apiService().createPartialSubmission(data)
          .then((response) => {
            if (response.status === 'created' || response.status === 'success') {
              this.$router.push(`/submission/${response.data.uuid}/edit`);
            } else {
              this.errors = utils.flattenApiValidationErrors(response.data);
            }
            this.isSaving = false;
          })
          .catch((errorResponse) => {
            this.errors = errorResponse.data;
            if(this.errors?.owners.length && this.errors.owners[0]?.phonenumbers.length && this.errors.owners[0].phonenumbers[0]?.number.length) {
              this.errors.owners[0].phonenumbers[0].number = 'Owner Phone number is invalid';
            }
            if(this.errors?.owners.length && this.errors.owners[0]?.first_name?.regex.length) {
              this.errors.owners[0].first_name = 'The Owner First Name can’t start with a number';
            }
            if(this.errors?.owners.length && this.errors.owners[0]?.last_name?.regex.length) {
              this.errors.owners[0].last_name = 'The Owner Last Name can’t start with a number';
            }
            this.isSaving = false;
          });
    },
    handleOnBack() {
      this.$router.back();
    }
  },
  watch: {
    form: {
      handler() {
        this.errors = {};
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
