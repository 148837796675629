<template>
  <div class="container-fluid h-100 login">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-3 text-center">
        <img src="/img/KapitusR_Logo.png" class="logo mw-100" alt="kapitus logo">
      </div>
    </div>
    <br/>
    <div class="row justify-content-center text-center mt-4 mb-4">
      <div class="col-md-12 col-lg-3">
        <h3 class="title" v-if="step===1">Forgot your password?</h3>
        <div class="title title-secondary fs-19" v-if="step===1">Enter your email address below and we will send you a verification code to reset your password.</div>
        <div class="title title-secondary fs-19" v-if="step===2">Please enter your verification code and your new password.</div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-3">
        <div>
          <div v-if="error" :class="['error-feedback', 'p-2']" role="alert">{{ (error.message || error.toString()) }}</div>
        </div>
        <Form @submit="handleSubmit" :validation-schema="schema">
          <div :class="'form-group' + (step===1 ? '' : ' hidden')">
            <label for="username">Email</label>
            <Field name="username" type="email" class="form-control" placeholder="Email address" id="username" :disabled="step !== 1"/>
            <ErrorMessage name="username" class="error-feedback"/>
          </div>
          <div class="form-group" v-if="step===2">
            <label for="code">Verification Code</label>
            <Field name="code" type="number" class="form-control" id="code"/>
            <ErrorMessage name="code" class="error-feedback"/>
          </div>
          <div class="form-group" v-if="step===2">
            <label for="newPassword">New Password</label>
            <Field name="newPassword" type="password" class="form-control" id="newPassword" @keyup="validatePasswordRules" v-model="input.password"/>
            <ErrorMessage name="newPassword" class="error-feedback"/>
            <div v-if="displayIncorrectPasswordAlertHtml" :class="['password-notice']" role="alert" >
              <p class="mb-0 mt-2 pb-0">Password must meet the following requirements:</p>
                <ul class="ml-0 pl-4 mb-0 mt-2">
                  <li :class="passwordRules.minLength ? satisfiedClass : notSatisfiedClass" id="ruleMinLength">Minimum length is 8 characters.</li>
                  <li :class="passwordRules.spaces ? satisfiedClass : notSatisfiedClass" id="ruleSpaces">Cannot start/end with a space.</li>
                  <li :class="passwordRules.hasNumber ? satisfiedClass : notSatisfiedClass" id="ruleNumber">Must contain a number.</li>
                  <li :class="passwordRules.specialChar ? satisfiedClass : notSatisfiedClass" id="ruleSpecialChar">Must contain a special character.</li>
                  <li :class="passwordRules.uppercase ? satisfiedClass : notSatisfiedClass" id="ruleUppercase">Must contain an uppercase letter.</li>
                  <li :class="passwordRules.lowercase ? satisfiedClass : notSatisfiedClass" id="ruleLowercase">Must contain a lowercase letter.</li>
                </ul>
            </div>
          </div>
          <div class="form-group" v-if="step===2">
            <label for="newPasswordConfirmation">Repeat New Password</label>
            <Field name="newPasswordConfirmation" type="password" class="form-control" id="newPasswordConfirmation" @keyup="validatePasswordRules" v-model="input.passwordRepeated"/>
            <ErrorMessage name="newPasswordConfirmation" class="error-feedback"/>
          </div>
          <div class="vue3-input">
            <button :class="['kap-btn-primary-inverted', 'mt-3', 'mb-3', 'btn big-button-100pct', 'navigation-button']" :disabled="loading || (!rulesSatisfied && step !== 1)">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span><span class="fs-18"> {{ actionButtonLabel }}</span>
            </button>
          </div>
          <div class="row justify-content-center text-center mb-3">
            <a href="/#" class="white-link text-center fw-600" v-bind:onclick="handleCancel" id="cancel-button">Back to Log In</a>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import {ErrorMessage, Field, Form} from 'vee-validate';
import * as yup from "yup";
import utils from "../../utils/";
export default {
  name: "RemindPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  cachedUser: {},
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!").email("Email must be valid"),
    });

    return {
      loading: false,
      successMessage: null,
      error: null,
      actionButtonLabel: 'Send Code',
      step: 1,
      schema,
      cachedUser: {},
      cognitoUser: null,
      displayIncorrectPasswordAlertHtml: true,
      input: {
        password: '',
        passwordRepeated: ''
      },
      passwordRules: {
        minLength: false,
        spaces: false,
        hasNumber: false,
        specialChar: false,
        uppercase: false,
        lowercase: false,
        passwordMatch: false,
      },
      rulesSatisfied: false,
      satisfiedClass: 'rule-satisfied',
      notSatisfiedClass: 'rule-not-satisfied'
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/dashboard');
    }
  },
  methods: {
    handleCancel() {
      this.$router.push('/login');
    },
    handleSubmit(user) {
      this.loading = true;
      this.$data.error = null;

      if (this.$data.step === 1) {
        this.$data.cachedUser.email = user.username;
        this.$store.dispatch('auth/command', {user, commandName: 'sendForgotPasswordCode'})
            .then(() => {
              this.loading = false;
              this.$data.step = 2;
              this.$data.actionButtonLabel = 'Change Password';
              this.$data.schema = yup.object().shape({
                code: yup.string().required("Code is required!"),
                username: yup.string().required("Username is required!").email(),
                newPassword: yup.string().required("Password is required!"),
                newPasswordConfirmation: yup.string().required('Passwords must match').oneOf([yup.ref('newPassword'), null], 'Passwords must match')
              })
            })
            .catch((error) => {
                  this.loading = false;
                  this.$data.error = error;
                }
            );
       } else if (this.$data.step === 2 && user.code) {
        this.$data.actionButtonLabel = 'Change Password';
        this.$store.dispatch('auth/command', {user, commandName: 'setPasswordUsingCode'})
            .then(() => {
              this.loading = false;
              this.$router.push('/login');
            })
            .catch((error) => {
                  this.loading = false;
                  this.$data.step = 2;
                  this.$data.error = error;
                }
            );
      }
    },
    validatePasswordRules() {
      utils.validatePassword(this.passwordRules, this.input.password, this.input.passwordRepeated);
      this.rulesSatisfied = utils.checkRulesSatisfied(this.passwordRules);
    },
  },
};
</script>
