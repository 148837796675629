<template>
  <div></div>
</template>
<script>
import interceptor from "@/plugins/interceptor";

export default {
  name: "LoginRedirect",

  created() {
    const userData = this.$route.query;
    if (!userData.access_token) {
      this.$router.push("/login");
      return;
    }
    localStorage.setItem("userData", JSON.stringify(userData));
    this.$store.commit("auth/loginSuccess", userData);
    interceptor.resetInterceptors();
    interceptor.setAuthenticatedInterceptor(userData.access_token);
    this.$router.push("/dashboard");
  },
};
</script>

