<template>
  <div class="container kap-container company-color">
    <div class="content">
      <h4>Change Password</h4>
      <div class="row">
        <div class="col-md-12">
          <div>
            <div v-if="error" :class="['error-feedback', 'p-2']" role="alert">{{ (error.message || error.toString()) }}</div>
          </div>
          <Form @submit="handleSubmit" :validation-schema="schema">
            <div :class="'form-group' + (step===1 ? '' : ' hidden')">
              <label for="username">Email</label>
              <Field name="username" type="email" class="form-control" placeholder="Email address" id="username" readonly v-model="input.username" :disabled="step !== 1"/>
              <ErrorMessage name="username" class="error-feedback"/>
            </div>
            <div :class="'form-group' + (step===1 ? '' : ' hidden')">
              <label for="password">Current Password</label>
              <Field name="password" type="password" class="form-control" id="currentPassword" v-model="input.currentPassword" :disabled="step !== 1"/>
              <ErrorMessage name="password" class="error-feedback"/>
            </div>
            <div class="form-group" v-if="step===2">
              <label for="code">Verification Code</label>
              <Field name="code" type="number" class="form-control" id="code"/>
              <ErrorMessage name="code" class="error-feedback"/>
              <div :class="['password-notice']" role="alert" >
                <p class="mb-0 mt-2 pb-0">Verification Code has been sent to your email</p>
              </div>
            </div>
            <div class="form-group" v-if="step===2">
              <label for="newPassword">New Password</label>
              <Field name="newPassword" type="password" class="form-control" id="newPassword" @keyup="validatePasswordRules" v-model="input.password"/>
              <ErrorMessage name="newPassword" class="error-feedback"/>
              <div v-if="displayIncorrectPasswordAlertHtml" :class="['password-notice']" role="alert" >
                <p class="mb-0 mt-2 pb-0">Password must meet the following requirements:</p>
                  <ul class="ml-0 pl-4 mb-0 mt-2">
                    <li :class="passwordRules.minLength ? satisfiedClass : notSatisfiedClass" id="ruleMinLength">Minimum length is 8 characters.</li>
                    <li :class="passwordRules.spaces ? satisfiedClass : notSatisfiedClass" id="ruleSpaces">Cannot start/end with a space.</li>
                    <li :class="passwordRules.hasNumber ? satisfiedClass : notSatisfiedClass" id="ruleNumber">Must contain a number.</li>
                    <li :class="passwordRules.specialChar ? satisfiedClass : notSatisfiedClass" id="ruleSpecialChar">Must contain a special character.</li>
                    <li :class="passwordRules.uppercase ? satisfiedClass : notSatisfiedClass" id="ruleUppercase">Must contain an uppercase letter.</li>
                    <li :class="passwordRules.lowercase ? satisfiedClass : notSatisfiedClass" id="ruleLowercase">Must contain a lowercase letter.</li>
                  </ul>
              </div>
            </div>
            <div class="form-group" v-if="step===2">
              <label for="newPasswordConfirmation">Repeat New Password</label>
              <Field name="newPasswordConfirmation" type="password" class="form-control" id="newPasswordConfirmation" @keyup="validatePasswordRules" v-model="input.passwordRepeated"/>
              <ErrorMessage name="newPasswordConfirmation" class="error-feedback"/>
            </div>
            <div class="row mt-3" v-if="step < 3">
              <div class="col">
                <button type="submit" class="btn big-button navigation-button" :disabled="loading || (!rulesSatisfied && step !== 1)">
                  <span v-show="loading" class="spinner-border spinner-border-sm "></span>
                  <span>{{ actionButtonLabel }}</span>
                </button>
                <a href="javascript:void(0);" v-on:click="handlePasswordChangeCanceled" class="cancel-link pl-3" id="cancel-button">Cancel</a>
              </div>
            </div>
          </Form>
          <div v-if="step==3">
            <p>Your password has been changed successfully!</p>
            <div class="row mt-3">
              <div class="col">
                <a href="javascript:void(0);" v-on:click="handlePasswordChangeCanceled" class="btn btn-primary big-button kap-button company-link-bg">Back to Profile</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {ErrorMessage, Field, Form} from 'vee-validate';
import * as yup from "yup";
import utils from "../../utils/";

export default {
  name: 'ChangePassword',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  cachedUser: {},
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!").email("Email must be valid"),
    });

    const dispatcherAuthCommand = 'auth/command';

    return {
      userAttributes: this.$store.state.auth.userData,

      loading: false,
      successMessage: null,
      error: null,
      actionButtonLabel: 'Send Code',
      step: 1,
      schema,
      dispatcherAuthCommand,
      cachedUser: {},
      cognitoUser: null,
      displayIncorrectPasswordAlertHtml: true,
      input: {
        username: this.$store.state.auth.userData.email,
        currentPassword: '',
        password: '',
        passwordRepeated: ''
      },
      passwordRules: {
        minLength: false,
        spaces: false,
        hasNumber: false,
        specialChar: false,
        uppercase: false,
        lowercase: false,
        passwordMatch: false,
      },
      rulesSatisfied: false,
      satisfiedClass: 'rule-satisfied',
      notSatisfiedClass: 'rule-not-satisfied'
    };
  },
  methods: {
    handlePasswordChangeCanceled() {
      this.$router.push('/partner/' + this.$data.userAttributes.custom_partner_uuid);
    },
    handleSubmit(user) {
      this.loading = true;
      this.$data.error = null;

      if (this.$data.step === 1) {
        this.cognitoLogin(user);
       } else if (this.$data.step === 2 && user.code) {
        this.setPasswordUsingCode(user);
      }
    },
    cognitoLogin(user) {
      this.$data.cachedUser.email = user.username;
      this.$store.dispatch(this.$data.dispatcherAuthCommand, {user, commandName: 'cognitoLogin'})
        .then(() => {
          this.sendVerificationCode(user);
        })
        .catch((error) => {
          this.loading = false;
          if (error.code && error.code === 8001) {
            this.$data.error = 'Email and password combination is incorrect. Please try again';
          } else {
            this.$data.error = (error.code ? `Error ${error.code}: ` : '') + (error.message || error.toString());
          }
        });
    },
    sendVerificationCode(user) {
      this.$store.dispatch(this.$data.dispatcherAuthCommand, {user, commandName: 'sendForgotPasswordCode'})
        .then(() => {
          this.loading = false;
          this.$data.step = 2;
          this.$data.actionButtonLabel = 'Change Password';
          this.$data.schema = yup.object().shape({
            code: yup.string().required("Code is required!"),
            username: yup.string().required("Username is required!").email(),
            newPassword: yup.string().required("Password is required!"),
            newPasswordConfirmation: yup.string().required('Passwords must match').oneOf([yup.ref('newPassword'), null], 'Passwords must match')
          })
        })
        .catch((error) => {
          this.loading = false;
          if (error.code && error.code === 44444) {
            this.$data.error = 'The confirmation code was not sent. Please try again';
          } else {
            this.$data.error = error;
          }
        });
    },
    setPasswordUsingCode(user) {
      this.$data.actionButtonLabel = 'Change Password';
      this.$store.dispatch(this.$data.dispatcherAuthCommand, {user, commandName: 'setPasswordUsingCode'})
        .then(() => {
          this.loading = false;
          this.$data.step = 3;
        })
        .catch((error) => {
              this.loading = false;
              this.$data.step = 2;
              this.$data.error = error;
            }
        );
    },
    validatePasswordRules() {
      utils.validatePassword(this.passwordRules, this.input.password, this.input.passwordRepeated);
      this.rulesSatisfied = utils.checkRulesSatisfied(this.passwordRules);
    },
  },
};
</script>
