import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import config from "./config";
import "./assets/css.js"; // <- css goes here
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faEdit, faAsterisk } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from './plugins/font-awesome';
import "bootstrap";
import PrimeVue from 'primevue/config';
import VueGtag from "vue-gtag";
import interceptor from "@/plugins/interceptor";

library.add(faBars, faEdit, faAsterisk);

if (store.state.auth?.userData?.access_token) {
    interceptor.setAuthenticatedInterceptor(store.state.auth.userData.access_token);
}

createApp(App)
    .use(router)
    .use(store)
    .use(PrimeVue)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(VueGtag, {
        appName: 'Kapitus Partner Portal',
        pageTrackerScreenviewEnabled: true,
        config: {
            id: config.Google.measurementId,
            user_id: store.state.auth.status.loggedIn ? store.state.auth.userData.email : null,
        },
        enabled: !!(config.Google.measurementId),
        // pageTrackerTemplate(to) {
        //     return {
        //         page_title: to.matched[0].meta.title,
        //         page_path: to.path
        //     }
        // }
    }, router)
    .mount("#app");
