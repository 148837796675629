export default Object.freeze({
    COGNITO_AUTH_FAILURE: 8001,
    COGNITO_GET_ATTRIBUTES_FAILED: 8002,
    API_AUTH_FAILURE: 8003,
    COGNITO_REQUIRES_REGISTRATION: 8004,
    API_CLIENT_SECRETS_MISSING: 8005,
    COGNITO_FAILED_SETTING_NEW_PASSWORD: 8006,
    COGNITO_FAILED_CHANGING_PASSWORD: 8007,
    COGNITO_FAILED_SEND_MFA_CODE: 8008,
});
