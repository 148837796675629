<template>
  <div class="row justify-content-center">
    <div class="col">
      <div>
        <div v-if="successMessage || error" :class="['alert', messageStyle, 'alert-dismissible', 'fade', 'show']" role="alert">
          {{ message }}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <Form @submit="handlePasswordSet" :validation-schema="schema">
        <div class="form-group">
          <label for="username">Email</label>
          <Field name="username" type="text" class="form-control" id="username" disabled="disabled" v-model="input.username"/>
          <ErrorMessage name="username" class="error-feedback"/>
        </div>
        <div class="form-group">
          <label for="password">Current Password</label>
          <Field name="password" type="password" class="form-control" id="password"/>
          <ErrorMessage name="password" class="error-feedback"/>
        </div>
        <div class="form-group">
          <label for="newPassword">New Password</label>
          <Field name="newPassword" type="password" class="form-control" id="newPassword" @keyup="validatePasswordRules" v-model="input.password"/>
          <ErrorMessage name="newPassword" class="error-feedback"/>
          <div v-if="displayIncorrectPasswordAlertHtml" :class="['password-notice']" role="alert" >
            <p class="mb-0 mt-2 pb-0">Password must meet the following requirements:</p>
            <ul class="ml-0 pl-4 mb-0 mt-2">
              <li :class="passwordRules.minLength ? satisfiedClass : notSatisfiedClass" id="ruleMinLength">Minimum length is 8 characters.</li>
              <li :class="passwordRules.spaces ? satisfiedClass : notSatisfiedClass" id="ruleSpaces">Cannot start/end with a space.</li>
              <li :class="passwordRules.hasNumber ? satisfiedClass : notSatisfiedClass" id="ruleNumber">Must contain a number.</li>
              <li :class="passwordRules.specialChar ? satisfiedClass : notSatisfiedClass" id="ruleSpecialChar">Must contain a special character.</li>
              <li :class="passwordRules.uppercase ? satisfiedClass : notSatisfiedClass" id="ruleUppercase">Must contain an uppercase letter.</li>
              <li :class="passwordRules.lowercase ? satisfiedClass : notSatisfiedClass" id="ruleLowercase">Must contain a lowercase letter.</li>
            </ul>
          </div>
        </div>
        <div class="form-group">
          <label for="newPasswordConfirmation">Repeat New Password</label>
          <Field name="newPasswordConfirmation" type="password" class="form-control" id="newPasswordConfirmation" @keyup="validatePasswordRules" v-model="input.passwordRepeated"/>
          <ErrorMessage name="newPasswordConfirmation" class="error-feedback"/>
        </div>
        <TermsNote v-if="secondaryButtonStyle" ref="termsNote"></TermsNote>
        <div class="input-group pt-2" v-if="secondaryButtonStyle">
          <label class="checkbox-all terms">I agree to Kapitus Terms and Conditions
            <Field name="terms" type="checkbox" autocomplete="off" value="true" id="terms" v-model="terms"/>
            <span class="checkmark"></span>
          </label>
        </div>
        <div v-if="secondaryButtonStyle">
          <button class="kap-btn-primary-inverted mt-3 mb-3 btn big-button-100pct navigation-button" 
            :disabled="loading || !rulesSatisfied || !terms" type="submit">
            <span v-show="loading" class="spinner-border spinner-border-sm "></span>
            <span class="fs-18">Save</span>
          </button>
        </div>
        <div v-if="secondaryButtonStyle" class="row justify-content-center text-center mb-3">
          <a href="javascript:void(0);" v-on:click="handleCancel" class="white-link text-center fw-600" id="cancel-button">Cancel</a>
        </div>
        <div v-if="!secondaryButtonStyle" class="row mt-3">
          <Field name="terms" type="hidden" checked autocomplete="off" value="true" id="terms" v-model="terms"/>
          <div class="col">
            <button type="submit" class="btn big-button navigation-button" :disabled="loading || !rulesSatisfied">
              <span v-show="loading" class="spinner-border spinner-border-sm "></span>
              <span>Save</span>
            </button>
            <a href="javascript:void(0);" v-on:click="handleCancel" class="cancel-link pl-3" id="cancel-button">Cancel</a>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import * as yup from "yup";
import utils from "../../utils/";
import TermsNote from "../Note/TermsNote";

export default {
  name: "SetPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
    TermsNote,
  },
  emits: ['password-set', 'password-change-canceled'],
  props: {
    'username': String,
    'error': Object,
    'successMessage': String,
    'primaryButtonStyle': String,
    'secondaryButtonStyle': String,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!").email().oneOf([this.$props.username], 'Unknown username'),
      password: yup.string().required("Password is required!"),
      newPassword: yup.string().required("Password is required!"),
      newPasswordConfirmation: yup.string().required('Passwords must match').oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
      terms: yup.bool().oneOf([true], 'Terms agreement is required!'),
    });

    return {
      loading: false,
      terms: false,
      schema,
      input: {
        username: this.$props.username,
        password: '',
        passwordRepeated: ''
      },
      displayIncorrectPasswordAlertHtml: true,
      passwordRules: {
        minLength: false,
        spaces: false,
        hasNumber: false,
        specialChar: false,
        uppercase: false,
        lowercase: false,
        passwordMatch: false,
      },
      rulesSatisfied: false,
      satisfiedClass: 'rule-satisfied',
      notSatisfiedClass: 'rule-not-satisfied'
    };
  },
  computed: {
    message() {
      const error = this.$props.error;
      const errorCode = (error && error.code ? error.code + ': ' : '');
      return error ? 'Error ' + errorCode + (error.message || error.toString()) : this.$props.successMessage;
    },
    messageStyle() {
      return this.$props.error ? 'alert-danger' : 'alert-success';
    }
  },
  methods: {
    handlePasswordSet(user) {
      this.loading = true;
      this.$emit('password-set', user);
    },
    handleCancel(ev) {
      ev.preventDefault();
      this.$emit('password-change-canceled');
    },
    validatePasswordRules() {
      utils.validatePassword(this.passwordRules, this.input.password, this.input.passwordRepeated);
      this.rulesSatisfied = utils.checkRulesSatisfied(this.passwordRules);
      this.displayIncorrectPasswordAlertHtml = !this.rulesSatisfied;
    },
  },
  watch: {
    $props: {
      handler() {
        this.loading = false;
      },
      deep: true,
      immediate: true,
    }
  }
};
</script>

<style scoped>

</style>
