export default {
    storage: localStorage,
    getItem(itemName) {
        return this.storage.getItem(itemName);
    },
    setItem(itemName, value) {
        this.storage.setItem(itemName, value);
    },
    getItemFromJson(itemName) {
        return JSON.parse(
            this.getItem(itemName)
        );
    },
    setItemAsJson(itemName, value) {
        this.setItem(itemName, JSON.stringify(value));
    },
    clear() {
        this.storage.clear();
    }
}
